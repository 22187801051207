@import "base-colors.scss";

[data-namespace="RT46"] {
  .simple-cta-container {
    background: $base-accent-color;
    color: #fff;
    padding: 40px;
    &.services {
      background: #fff;
      color: $base-font-color;
    }
    @media(max-width: 768px){
      padding: 40px 20px;
    }
    .container {
      display: flex;
      justify-content: space-around;
      @media(max-width: 768px) {
        display: block;
        text-align: center;
      }
    }
    h3 {
      font-size: 42px;
      position: relative;
      top: 8px;
      @media(max-width: 768px) {
        top: 0;
        margin-bottom: 20px;
      }
      @media(max-width: 480px) {
        font-size: 32px;
      }
    }
    .button-trigger {
      &.phone-trigger-container {
        font-size: 32px;
        height: 82px;
        line-height: 32px;
        padding: 8px 45px 8px 90px;
        &:hover, &:active, &:focus {
          background: #b51f14;
        }
        img {
          width: 50px;
          top: 12px;
          left: 25px;
          @media(max-width: 480px) {
            width: 30px;
            top: 12px;
            left: 15px;
          }
        }
        label {
          font-size: 23px;
          @media(max-width: 480px) {
            font-size: 16px;
          }
        }
        @media(max-width: 1024px){
          font-size: 20px;
        }
        @media(max-width: 480px) {
          font-size: 24px;
          height: 60px;
          line-height: 20px;
          padding: 8px 45px 8px 60px;
        }
        @media(max-width: 375px) {
          font-size: 20px;
          width: 100%;
        }
      }
    }
  }
}

@import "base-colors.scss";

[data-namespace="RT46"] {
  .page-wrapper {
    @include delayedLoader;
    .emergency-wrapper {
      display: none;
      @media(max-width: 480px) {
        display: block;
        .price-guide-wrapper {
          padding: 0 20px;
        }
      }
    }
    .non-emergency-wrapper {
      @media(max-width: 480px){
        display: none;
      }
    }
    .override-show {
      display: block !important;
    }
    .override-hide {
      display: none !important;
    }
  }
}

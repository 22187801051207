@import "base-colors.scss";

[data-namespace="RT46"] {
  footer {
    background: #191919;
    color: #fff;
    text-align: center;
    padding: 40px 0 80px;
    font-size: 14px;
    font-weight: 500;
    @media (max-width: 480px) {
      padding-bottom: 100px;
    }
    .container {
      padding: 0 20px;
    }
    a {
      color: #fff;
    }
    .logo-container {
      margin-bottom: 20px;
    }
    .phone-trigger {
      font-size: 20px;
      font-weight: 800;
      letter-spacing: -.05em;
    }
    .nav-panel {
      margin: 20px 0;
      a {
        display: inline-block;
        margin: 0 10px;
      }
    }
    .support-container {
      margin-top: 20px;
      @media(max-width: 480px){
        margin-top: 40px;
      }
    }
    .nav-bar {
      a {
        color: $base-accent-color;
        padding: 0 10px;
        font-size: 14px;
        font-weight: 400;
        &:first-of-type {
          border-right: 1px solid #fff;
        }
        @media(max-width: 480px){
          font-size: 12px;
        }
        @media(max-width: 375px){
          display: block;
          &:first-of-type {
            border-right: none;
          }
        }
      }
    }
  }
}

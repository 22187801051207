@import "base-colors.scss";

[data-namespace="RT46"] {
  .hero-service-container {
    background: linear-gradient( rgba(44, 43, 91, .1), rgba(2, 2, 13, .35) );
    background-size: cover;
    position: relative;
    padding: 30px 20px;
    @media(max-width: 480px) {
      padding-top: 40px;
    }
    .container {
      position: relative;
    }
    .logo-white-container {
      @media(max-width: 480px) {
        img {
          width: 100px;
        }
      }
    }
    .actions-container {
      position: absolute;
      top: 0;
      right: 0;
      @media(max-width: 480px){
        top: -10px;
      }
      .phone-trigger-container {
        @media(max-width: 480px){
          img {
            display: none;
          }
        }
      }
      .button-trigger {
        @media(max-width: 480px) {
          position: relative;
          top: -15px;
          height: 40px;
          padding: 3px 20px;
          &:first-of-type {
            display: none;
          }
          .desktop {
            display: none;
          }
        }
      }
    }
    h1 {
      text-align: center;
      color: #fff;
      font-size: 40px;
      margin: 90px 0 30px;
      @media(max-width: 1024px) {
        margin-top: 60px;
      }
      @media(max-width: 768px) {
        width: 100%;
        font-size: 28px;
        line-height: 32px;
        margin-top: 40px;
      }
    }
  }
}

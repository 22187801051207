@import "base-colors.scss";

[data-namespace="RT46"] {
  .phone-trigger-container {
    font-family: "Work Sans", sans-serif !important;
    height: 50px;
    position: relative;
    text-align: center;
    padding: 3px 25px 3px 50px;
    font-weight: 700;
    line-height: 20px;
    color: #fff;
    display: block;
    font-size: 14px;
    &:hover, &:active, &:focus {
      color: #1aaee4;
    }
    @media(max-width: 480px){
      text-align: center;
      font-size: 11px;
      line-height: 1.2em;
      height: auto;
      padding-right: 0;
    }
    img {
      position: absolute;
      left: 15px;
      top: 6px;
      width: 30px;
      @media(max-width: 480px){
        position: static;
        width: 20px;
      }
    }
    label {
      display: block;
      font-weight: 400;
    }
  }
}

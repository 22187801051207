@import "base-colors.scss";

[data-namespace="RT46"] {
  font-family: "Work Sans", sans-serif;
  .button-trigger {
    display: inline-block;
    vertical-align: middle;
    padding: 8px 15px;
    margin: 0 5px;
    border: 1px solid transparent;
    text-align: center;
    background: $base-cta-color;
    font-family: "Work Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    color: white !important;
    border-radius: 50px;
    cursor: pointer;
    transition: all .25s ease;
    &:hover, &:active, &:focus {
      background: $base-cta-hover-color;
      color: $base-font-color;
    }
    &.alt {
      border: 2px solid #ffffff;
      background: transparent;
      font-size: 12.6px;
      font-weight: 600;
      line-height: 16px;
      font-family: "Work Sans", sans-serif;
      height: 50px;
      padding: 7px 20px;
      &:hover, &:active, &:focus {
        border: 2px solid $base-cta-color !important;
        color: $base-cta-color !important;
      }
      &.inverse {
        border: 2px solid $base-cta-hover-color;
        color: $base-cta-hover-color !important;
        &:hover, &:active, &:focus {
          border: 2px solid $base-cta-color !important;
          color: $base-cta-color !important;
        }
      }
    }
  }
  .isLoadingSiteData {
    opacity: 0;
  }
}

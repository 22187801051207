@import "base-colors.scss";

[data-namespace="RT46"] {
  .price-guide-container {
    background: #fff;
    ul {
      padding: 15px;
      list-style: none;
      @media(max-width: 768px){
        padding: 0;
        border: 1px solid #dfdfdf;
        margin: 10px;
        border-bottom: none;
      }
      li {
        display: flex;
        justify-content: space-between;
        padding: 9px 20px;
        min-height: 40px;
        border-bottom: 1px solid #dfdfdf;
        font-size: 14px;
        @media(max-width: 480px){
          padding: 9px;
        }
        &:hover, &:active, &:focus {
          background-color: #F1F5F0;
        }
        &:last-of-type {
          border-bottom: none;
        }
        a {
          display: flex;
          justify-content: space-between;
          width: 100%;
          color: $base-font-color;
          label {
            cursor: pointer;
            flex: 3;
            @media(max-width: 480px){
              flex: 2;
            }
          }
          .category {
            flex: 3;
            @media(max-width: 480px){
              flex: 2;
            }
          }
          .price {
            text-align: right;
            flex: 2;
            @media(max-width: 480px){
              flex: 2;
            }
          }
        }
        &.header {
          height: auto;
          line-height: 17px;
          padding: 12px 20px;
          @media(max-width: 480px) {
            padding-left: 0;
          }
          &:hover, &:active, &:focus {
            background-color: transparent;
          }
          .icon-container {
            display: flex;
            justify-content: space-between;
            img {
              height: 42px;
              width: auto;
              margin-right: 10px;
              @media(max-width: 480px) {
                height: 30px;
              }
            }
            .content {
              font-size: 18px;
              color: #000;
              display: flex;
              justify-content: center;
              align-items: center;
              @media(max-width: 480px) {
                font-size: 18px;
              }
              strong {
                padding-left: 5px;
              }
            }
          }
          .title {
            text-align: center;
            span {
              display: block;
            }
          }
          @media(max-width: 768px) {
            border: 1px solid #fff;
            border-bottom: 1px solid #dfdfdf;
            position: relative;
            top: -1px;
            left: -1px;
            width: calc(100% + 2px);
          }
        }
        &.disclaimer {
          display: none;
        }
      }
      .desktop {
        height: auto;
        @media(max-width: 768px) {
          display: none;
        }
      }
      .mobile {
        display: none;
        border: 1px solid #fff;
        position: relative;
        bottom: -1px;
        left: -1px;
        top: 0;
        width: calc(100% + 2px);
        @media(max-width: 768px) {
          display: flex;
        }
      }
    }
  }
}

@import "base-colors.scss";

[data-namespace="RT46"] {
  .about-container {
    text-align: center;
    padding: 40px;
    @media(max-width: 480px) {
      padding: 40px 20px;
    }
    h3 {
      font-size: 42px;
      @media(max-width: 480px) {
        font-size: 25px;
        line-height: 1.2em;
      }
      span {
        display: block;
        margin: 8px auto;
        width: 60px;
        height: 3px;
        background: $base-accent-color;
      }
    }
    p {
      color: #a1a1a1;
      font-size: 15px;
      margin: 20px auto;
      font-weight: 500;
      width: 70%;
      @media(max-width: 480px) {
        font-size: 13px;
        margin-bottom: 0;
        padding: 0 30px;
        width: 100%;
      }
    }
  }
}

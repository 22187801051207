@import "base-colors.scss";

[data-namespace="RT46"] {
  .hero-emergency-container {
    background: linear-gradient( rgba(44, 43, 91, .1), rgba(2, 2, 13, .35) );
    background-size: cover;
    position: relative;
    padding: 30px 20px;
    margin-bottom: 20px;
    @media(max-width: 480px) {
      padding-top: 10px;
      background-position: left center !important;
    }
    .container {
      position: relative;
    }
    .logo-white-container {
      padding-top: 10px;
      display: inline-block;
      img {
        cursor: pointer;
      }
      @media(max-width: 480px) {
        img {
          width: 150px;
        }
      }
    }
    .actions-container {
      position: absolute;
      top: 15px;
      right: 0;
      @media(max-width: 768px) {
        top: 0;
      }
    }
    h1 {
      color: #fff;
      font-size: 40px;
      line-height: 54px;
      text-align: center;
      margin-top: 0;
      font-weight: 900;
      @media(max-width: 768px) {
        margin-bottom: 50px;
      }
      @media(max-width: 480px) {
        margin-top: 80px;
        font-size: 32px;
        line-height: 37px;
        font-weight: 700;
      }
    }
    h2 {
      color: #fff;
      font-size: 40px;
      line-height: 50px;
      font-weight: 500;
      text-align: center;
      @media(max-width: 480px) {
        font-size: 32px;
        line-height: 33px;
        margin-top: 10px;
      }
    }
    .buttons-container {
      display: block;
      margin: 40px auto 80px;
      padding: 0;
      width: 320px;
      @media(max-width: 320px){
        width: 100%;
      }
      .button-trigger {
        display: flex;
        align-items: center;
        white-space: nowrap;
        padding: 3px 8px;
        font-weight: 700;
        font-size: 18px;
        margin: 20px auto;
        @media(max-width: 768px) {
          width: 70%;
        }
        @media(max-width: 480px) {
          width: 100%;
          font-size: 16px;
        }
        @media(max-width: 320px) {
          font-size: 14px;
        }
        svg {
          width: 30px;
          height: auto;
          margin-right: 8px;
          path {
            fill: #fff;
          }
          @media(max-width: 480px) {
            width: 20px;
          }
        }
      }
    }
    .icons-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 700px;
      margin: 0 auto;
      .icon-container {
        a {
          display: flex;
          justify-content: space-between;
          margin-left: 20px;
          @media(max-width: 480px){
            display: block;
            text-align: center;
            margin-bottom: 30px;
          }
          img {
            height: 32px;
            width: auto;
            margin-right: 10px;
            @media(max-width: 480px){
              margin-right: 0;
            }
          }
          .content {
            display: flex;
            justify-content: center;
            flex-direction: column;
            color: #fff;
            @media(max-width: 480px){
              display: block;
              font-size: 13px;
            }
            @media(max-width: 320px){
              display: block;
              font-size: 11px;
            }
            strong {
              display: block;
            }
          }
        }
      }
    }
    .hide-me {
      display: none;
    }
  }
}

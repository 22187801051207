@import "base-colors.scss";

[data-namespace="RT46"] {
  .nav-panel-container {
    text-align: left;
    a {
      height: 52px;
      width: 380px;
      border-left: 4px solid #787878;
      background: #dbdbdb;
      line-height: 52px;
      font-size: 20px;
      color: #323232;
      position: relative;
      display: block;
      margin-bottom: 16px;
      padding-left: 15px;
      transition: all .25s ease;
      svg {
        transition: all .25s ease;
        path {
          transition: all .25s ease;
        }
      }
      &.active {
        color: #fff;
        background: $base-cta-hover-color;
        border-left-color: $base-accent-color;
        font-weight: 600;
        cursor: default;
        svg {
          transform: rotate(0deg);
          path {
            fill: #fff;
          }
        }
        &:hover, &:active, &:focus {
          svg {
            right: 5px;
          }
        }
      }
      &:hover, &:active, &:focus {
        color: #fff;
        background: $base-cta-hover-color;
        border-left-color: $base-accent-color;
        font-weight: 600;
        svg {
          right: 2px;
          path {
            fill: #fff;
          }
        }
      }
      @media(max-width: 480px){
        width: 100%;
      }
      @media(max-width: 320px){
        font-size: 17px;
      }
      svg {
        position: absolute;
        right: 5px;
        top: 15px;
        height: 22px;
        width: auto;
        transform: rotate(180deg);
        path {
          fill: #323232;
        }
      }
    }
  }
}
